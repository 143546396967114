<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Backoffice level</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-backoffice>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New level
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-table
        hover
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
      >
        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon-gear-fill></b-icon-gear-fill>
            </template>
            <b-dropdown-item
              href="#"
              v-b-toggle.sidebar-backoffice
              @click="
                currentItem = row.item;
                tmpItem = JSON.parse(JSON.stringify(row.item));
              "
              >Edit</b-dropdown-item
            >

            <b-dropdown-item
              href="#"
              @click="
                currentItem = row.item;
                showDeleteModal = true;
              "
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>
    <!-- </b-overlay> -->
    <b-sidebar
      class="right-sidebar"
      id="sidebar-backoffice"
      right
      shadow
      lazy
      @shown="loadMenu"
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">Edit level</h4>
        <h4 v-else>New level</h4>

        <b-row v-if="currentItem" class="mt-3">
          <b-col>
            <b-form-group label="Name">
              <b-form-input
                v-model="tmpItem.text"
                :placeholder="tmpItem.text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-else class="mt-3">
          <b-col>
            <b-form-group label="Name">
              <b-form-input v-model="tmpItem.nome" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-card v-if="currentItem">
          <div
            class="mt-4"
            v-for="group in this.sidebarMenu.menu"
            :key="group.group"
          >
            <h4>{{ group.group }}</h4>

            <div v-for="item in group.items" :key="item.id">
              <b-checkbox
                switch
                :checked="item.enabled"
                v-model="item.enabled"
                class="mt-1"
                >{{ item.label }}</b-checkbox
              >
              <div class="pl-4" v-for="subItem in item.items" :key="subItem.id">
                <b-checkbox
                  switch
                  :checked="subItem.enabled"
                  v-model="subItem.enabled"
                  >{{ subItem.label }}</b-checkbox
                >
              </div>
            </div>
          </div>
        </b-card>
        <div class="mt-5 mb-5">
          <b-button
            v-if="isLoading"
            squared
            variant="primary"
            size="lg"
            class="big-button"
            disabled
            ><b-spinner small type="grow"></b-spinner> Loading...</b-button
          >
          <b-button
            v-else
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Save</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentItem.value)"
      >Do you really want to delete the selected element?</b-modal
    >
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
export default {
  components: {},
  data() {
    return {
      items: null,
      fields: [
        // { key: "id", label: "ID" },
        { key: "value", label: "ID" },
        // { key: "cognome", label: "Surname" },
        // { key: "email", label: "Email" },
        // { key: "role", label: "Role" },
        { key: "text", label: "Level" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      advancedFilter_visible: false,
      currentItem: null,
      tmpItem: {
        ID: 0,
        Name: "",
        Menu: {},
      },
      showDeleteModal: false,

      isLoading: false,

      sidebarMenu: {},
    };
  },
  service: null,
  serviceDel: null,
  created() {
    this.service = new AxiosService("System/CreateBackofficeLevel");
    this.serviceDel = new AxiosService("System/DeleteBackofficeLevel");
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    loadItems() {
      this.service.getOptions("LoginLevels").then((res) => {
        this.items = res;
      });
    },
    loadMenu() {
      if (this.tmpItem.value) {
        this.service
          .readCustomEndpoint("System/GetBackofficeLevel/" + this.tmpItem.value)
          .then((res) => {
            console.log(res);
            this.sidebarMenu = res.menu;
          });
      }
    },
    resetData() {
      console.log("CHIUDO LA SIDEBAR", this.currentItem, this.tmpItem);
      this.currentItem = null;
      this.tmpItem = {
        text: "",
        value: 0,
      };
      this.sidebarMenu = {};
    },
    async saveItem() {
      if (this.tmpItem.value) {
        let obj = {
          ID: this.tmpItem.value,
          Name: this.tmpItem.text,
          menu: this.sidebarMenu,
        };
        this.service
          .putCustomEndpoint(
            "System/UpdateBackofficeLevel/",
            this.tmpItem.value,
            obj
          )
          .then((res) => {
            if (res) {
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .create({}, [{ param: "name", value: this.tmpItem.nome }])
          .then((res) => {
            if (res) {
              this.loadItems();
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$$errorToast();
            return false;
          });
      }

      // console.log(this.sidebarMenu);
      // this.isLoading = true;
      // this.service
      //   .create({}, [{ param: "name", value: this.tmpItem.nome }])
      //   .then((res) => {
      //     if (res) {
      //       this.isLoading = false;
      //     }
      //   });
    },
    deleteItem(id) {
      console.log(id);
      this.serviceDel
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.value
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0;
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>